@use '../../../../styles';

.container {
  display: flex;
  justify-content: space-between;
  gap: 10em;
  height: calc(100vh - 80px);
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 10em var(--site-content2-half-margin) 10em
      var(--site-content2-half-margin);
    gap: 6em;
  }
}

.congratsInfo {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.5em;

  @media (max-width: 1000px) {
    margin: 0 auto;
    align-items: center;
  }

  @media (max-width: 768px) {
    flex-basis: 80%;
  }

  h1 {
    color: styles.$green-1;
  }

  h4 {
    color: styles.$gray-1;
  }

  > p {
    color: styles.$gray-7;
  }

  .button {
    margin-top: 2em;
  }

  .resendEmail {
    color: styles.$gray-2;
    @extend .button-3;
  }
}

.image {
  flex-basis: 60%;
  object-fit: contain;
  max-width: 871px;
  @media (max-width: 1000px) {
    max-width: 650px;
    min-width: 260px;
    margin: 0 auto;
  }
}
