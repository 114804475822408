@use '../../../../styles';

.pageWrapper {
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.container {
  @extend .gray-shadow-6;
  width: 65%;
  margin: 10em auto;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: styles.$white-4;
  @media (max-width: 768px) {
    width: var(--site-content-width);
  }
  img {
    @media (max-width: 768px) {
      width: 50%;
      max-width: var(--site-min-width);
    }
  }
  h2 {
    width: 85%;
  }
  > :first-child {
    position: relative;
    top: -8em;
    margin-bottom: -6em;
    @media (max-width: 768px) {
      top: -6em;
    }
  }
  .resendEmail {
    color: styles.$gray-2;
    @extend .button-3;
    cursor: pointer;
  }
}

.form {
  margin-top: 4em;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 2em;
  @media (max-width: 768px) {
    width: var(--site-content2-width);
  }
  .formLine {
    display: flex;
    justify-content: space-between;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6em;
  margin: 3em 0;
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1em;
    margin-bottom: 3em;
    @media (max-width: 768px) {
      margin: 0 auto;
    }
    p {
      white-space: nowrap;
    }
  }
}
