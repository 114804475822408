@use '../../../styles';

.container {
  width: 1032px;
  padding: 4em 5.5em 4em 2em;
  display: flex;
  align-items: center;
  gap: 4em;
  @media (max-width: 768px) {
    padding: 2em;
    flex-direction: column;
  }
  img {
    width: 416px;
    @media (max-width: 768px) {
      width: 80%;
    }
  }
}

.orangeBold b {
  color: styles.$orange-1;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 3.5em;

  .header {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }

  .stats {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    .statsPlacement {
      margin-top: 1em;
    }
  }

  .buttons {
    display: flex;
    gap: 1.5em;
    align-items: center;

    > :first-child {
      width: 200px;
    }
  }
}
