@use '../../../styles';

.container {
  width: 573px;
  padding: 2.5em 3em;
  display: flex;
  flex-direction: column;
  gap: 2em;

  > img {
    align-self: flex-end;
  }

  h3 {
    color: styles.$blue-9;
  }
}

.buttons {
  margin-top: 2em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5em;

  > :first-child {
    width: 200px;
  }

  > * {
    cursor: pointer;
  }
}
