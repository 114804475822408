@use '../../../styles';

.container {
  position: relative;
}

.dropdown {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    width: 100%;
  }
}

.dropdownArrow {
  cursor: pointer;
}

.list {
  @extend .gray-shadow-5;
  position: absolute;
  top: calc(100% + 1em);
  left: 0;
  width: 100%;
  overflow-y: hidden;
  user-select: none;
  z-index: 1;
  color: styles.$gray-9;
  display: flex;
  flex-direction: column;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;
    padding: 0.8rem 1.6rem;
    border-radius: 8px;

    &:hover {
      color: styles.$black-1;
    }

    &.selected {
      background-color: rgba(styles.$blue-8, 8%);
      color: styles.$blue-8;
    }

    svg {
      flex-shrink: 0;
    }
  }
}
