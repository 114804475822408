.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: 1368px) {
    display: none;
  }
  img {
    &:nth-child(1) {
      width: 151px;
      height: 163px;
      position: absolute;
      top: 0;
      left: 20em;
    }
    &:nth-child(2) {
      width: 150px;
      height: 140px;
      position: absolute;
      top: 6em;
      left: 0;
    }
    &:nth-child(3) {
      width: 112px;
      height: 103px;
      position: absolute;
      top: 15em;
      left: 26em;
    }
    &:nth-child(4) {
      width: 138px;
      height: 170px;
      position: absolute;
      top: 22em;
      left: 7em;
    }
    &:nth-child(5) {
      width: 141px;
      height: 128px;
      position: absolute;
      top: 27em;
      left: 20em;
    }
  }
}
