@use '../../styles';

.allCards {
  display: flex;
  flex-wrap: wrap;
  width: var(--site-content-width);
  margin: 8em auto 8em auto;
  gap: 3em;
  @media (max-width: 768px) {
    width: var(--site-content2-width);
    justify-content: center;
    margin: 3em auto 1em auto;
  }
  .singleCard {
    cursor: pointer;
    flex: 1 0 31%;
    @extend .gray-shadow-1;
    border-radius: 18px;
    background-color: styles.$gray-11;
    padding-top: 2.5em;
    padding-bottom: 2.5em;
    padding-left: 2em;
    padding-right: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 285px;
    height: 389px;
    max-width: 394px;
    img {
      width: 87px;
      height: 116px;
      object-fit: contain;
      margin: 0 auto;
    }
    &:first-child {
      width: 515px;
      max-width: 515px;
      flex: 0 0 auto;
      @media (max-width: 768px) {
        width: 100%;
        max-width: unset;
      }
    }
    &:nth-child(2) {
      width: 332px;
      flex: 1 0 auto;
    }
    &:nth-child(3) {
      width: 332px;
      flex: 1 0 auto;
    }
  }
}
