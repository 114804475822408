@use '../../../../styles';

.wrapper {
  margin: 20em auto 4em auto;
  @media (max-width: 1000px) {
    margin: 10em auto 4em auto;
  }
  .cardWrapper {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .cardUpper {
    position: relative;
    top: -10rem;
    height: calc(15em + 20vw);
    img {
      @media (max-width: 650px) {
        height: 250px;
        object-fit: contain;
      }
    }
    @media (max-width: 650px) {
      top: 0;
      height: 100%;
    }
  }
  .cardUpperFlipped {
    top: 0;
    height: 95%;
    display: flex;
    flex-direction: column;
    row-gap: 4em;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
  }
  .cardBottom {
    position: relative;
    top: -5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 650px) {
      top: 0;
    }
    h1 {
      @media (max-width: 1400px) {
        font-weight: 700;
        font-size: calc(3rem + 2vw);
        line-height: calc(3.5rem + 2vw);
      }
    }
  }
  .cardBottomFlipped {
    height: 5%;
    justify-content: flex-end;
  }
  .openCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    div {
      cursor: pointer;
    }
  }
  .schoolFlipped {
    @extend .gradient-1;
  }
  .kinderGartenFlipped {
    @extend .gradient-4;
  }
  .cardWrapperFlipped {
    align-items: center;
    position: relative;
    p {
      color: styles.$white-2;
    }
  }
}
