@use '../../../styles';

.container {
  width: 1032px;
  display: flex;
  padding: 4em 10em 4em 1.5em;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2em;
  }
  img {
    height: 200px;
    width: auto;
    object-fit: contain;
    margin-bottom: 5em;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 3em;

  h3 {
    color: styles.$blue-9;
  }
}

.button {
  width: 200px;
  @media (max-width: 768px) {
    align-self: center;
  }
}
