@use '../../../styles';

.counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid styles.$gray-2;
  padding: 0.4rem 0.6rem;
  gap: 1em;
}

.disabled {
  cursor: not-allowed !important;
}

.input {
  width: 100%;

  input {
    width: 100%;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  svg {
    cursor: pointer;
  }
}
