.socialMedia {
  margin: 10rem auto;
}

.cardWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 650px) {
    justify-content: center;
  }
  h1 {
    margin-bottom: 3rem;
  }
}

.youtube {
  cursor: pointer;
  width: 60%;
  @media (max-width: 650px) {
    width: 100%;
  }
}

.facebook {
  cursor: pointer;
  width: 40%;
  @media (max-width: 650px) {
    width: 100%;
  }
}
