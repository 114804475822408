@use '../../styles';

.subHeader {
  color: styles.$white-2;
  justify-content: center;
  column-gap: 3em;
  padding: 1em 0;
  @media (max-width: 768px) {
    text-align: center;
  }
}
