@use '../../../styles';

.dropdown {
  position: relative;
  .dropdownTitle {
    @extend .field-1;
    &:hover {
      cursor: pointer;
    }
  }
  .dropdownChildren {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    flex-direction: column;
    gap: 1rem;
    background-color: styles.$white-3;
    min-height: 20rem;
    min-width: 20rem;
    width: max-content;
    z-index: 1;
    border-radius: 8px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: 1px solid styles.$gray-1;
    @extend.gray-shadow-1;
  }
  &:hover {
    > div {
      display: flex;
    }
  }
}

.subMenu {
  @extend .dropdownChildren;
  &:hover {
    display: flex;
  }
  left: 98% !important;
  top: 10% !important;
}

.item {
  height: 54px;
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  li {
    padding-left: 10px;
    list-style-type: none;
    color: styles.$gray-2;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @extend .body-3;
    &:hover {
      color: styles.$black-1;
    }
  }
  &:hover {
    background-color: styles.$gray-4;
    > div {
      > div {
        display: flex;
      }
    }
  }
}
