.parallax {
  width: 100%;
  overflow: hidden;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
}

.parallax .scroller {
  display: flex;
  flex-wrap: nowrap;
}
