@use '../../../../styles';

.wrapperClassName {
  display: flex;
  flex-direction: column-reverse;
}

.editorClassName {
  background-color: styles.$white-3;
  border-radius: 18px 18px 0 0;
  height: 13rem;
  max-height: 13rem;
  overflow-y: scroll;
  padding-left: 2rem;
  padding-right: 2rem;
  @extend .button-5;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.toolbarClassName {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0 0 18px 18px;
  border: none;
  margin: 0;
  padding: 1rem;
  border-top: 1px solid styles.$black-1;
  flex-wrap: wrap;
  > div {
    &:nth-child(1) {
      margin-right: 2rem;
    }
    &:last-child {
      margin-left: auto;
      @media (max-width: 320px) {
        margin-left: unset;
      }
    }
  }
  > div {
    margin: 0;
    > div {
      border: none;
    }
  }
}
