@use '../../styles';

.allCards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  width: var(--site-content-width);
  margin: 0 auto 8em auto;
  gap: 3em;
  .singleCard {
    cursor: pointer;
    flex: 0 0 23%;
    @extend .gray-shadow-5;
    border-radius: 18px;
    background-color: styles.$white-4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    min-width: 285px;
    max-width: 350px;
    margin: 0 auto;
    height: 434px;
    img {
      border-radius: 18px 18px 0 0;
      object-fit: cover;
    }
  }
}
