@use '../../../../styles';

.schools {
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 26px;
    }
    svg {
      cursor: pointer;
    }
  }
  .schoolName {
    text-align: center;
    font-weight: bold;
  }
  .addressOrName {
    text-align: center;
  }
  .bottomWinner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.resultsSummary {
  width: var(--site-content-width);
  margin: 0 auto;
}
