@use '../../styles';

.container {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}

.message {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  color: styles.$gray-2;
  height: 20px;

  &.error {
    &.themeRed {
      color: styles.$red-8;
    }

    &.themeWhite {
      color: styles.$white-2;

      svg {
        fill: styles.$white-2;
      }
    }
  }
}
