@use '../../../../styles';

.container {
  width: var(--site-content-width);
  margin: 0 auto 15rem auto;
  display: flex;
  gap: 2em;
  align-items: center;
  @media (max-width: 768px) {
    margin: 0 auto 5rem auto;
    flex-direction: column;
  }

  > img {
    width: 50%;
  }
}

.standings {
  > div {
    margin-top: 2.5rem;
    margin-bottom: 4rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 2.5rem;
  }

  > p {
    color: styles.$orange-1;
  }
}
