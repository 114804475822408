.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  @media (max-width: 1368px) {
    display: none;
  }
  img {
    &:nth-child(1) {
      width: 166px;
      height: 124px;
      position: absolute;
      top: 0;
      right: 20em;
    }
    &:nth-child(2) {
      width: 148px;
      height: 148px;
      position: absolute;
      top: 3em;
      right: 0;
    }
    &:nth-child(3) {
      width: 166px;
      height: 124px;
      position: absolute;
      top: 15em;
      right: 20em;
    }
    &:nth-child(4) {
      width: 148px;
      height: 148px;
      position: absolute;
      top: 22em;
      right: 0;
    }
    &:nth-child(5) {
      width: 150px;
      height: 70px;
      position: absolute;
      top: 30em;
      right: 20em;
    }
  }
}
