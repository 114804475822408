@use '../../../../../styles';

.slideshowItem {
  cursor: pointer;
  position: relative;
  height: 382px;
  width: 274px;
  border-radius: 18px;
  margin-right: 10px;
  > div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 18px;
    z-index: -2;
  }
  img {
    z-index: -1;
    position: absolute;
    top: 1% !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    left: 0;
    width: 97% !important;
    height: 50%;
    object-fit: cover;
    margin: 0 auto;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }
  .dateStamp {
    span {
      color: styles.$white-4;
      position: absolute;
      top: 2%;
      left: 6%;
      z-index: 1;
    }
  }
  svg {
    pointer-events: none;
    position: absolute;
    top: 1%;
    left: 1.5%;
  }
}
.slideshowItem1 {
  background: linear-gradient(
    180deg,
    #1d1d1f -4.84%,
    rgba(28, 35, 35, 0.968307) 0.47%,
    rgba(157, 198, 36, 0.04) 25.69%,
    #9dc624 51.15%,
    rgba(157, 198, 36, 0.981756) 56.7%,
    rgba(157, 198, 36, 0.72) 100%
  );
  div {
    background: #9dc624;
  }
}
.slideshowItem2 {
  background: linear-gradient(
    180deg,
    #1d1d1f -4.84%,
    rgba(28, 35, 35, 0.968307) 0.47%,
    rgba(255, 113, 36, 0.04) 25.69%,
    #ff7124 51.15%,
    rgba(255, 113, 36, 0.981756) 56.7%,
    rgba(255, 113, 36, 0.72) 100%
  );
  div {
    background: #ff7124;
  }
}
.slideshowItem3 {
  background: linear-gradient(
    180deg,
    #1d1d1f -4.84%,
    rgba(28, 35, 35, 0.968307) 0.47%,
    rgba(255, 72, 72, 0.04) 25.69%,
    #ff4848 51.15%,
    rgba(255, 72, 72, 0.981756) 56.7%,
    rgba(255, 72, 72, 0.72) 100%
  );
  div {
    background: #ff4848;
  }
}
.slideshowItem4 {
  background: linear-gradient(
    180deg,
    #1d1d1f -4.84%,
    rgba(28, 35, 35, 0.968307) 0.47%,
    rgba(14, 154, 255, 0.04) 25.69%,
    #0e9aff 51.15%,
    rgba(14, 154, 255, 0.981756) 56.7%,
    rgba(14, 154, 255, 0.72) 100%
  );
  div {
    background: #0e9aff;
  }
}
.slideshowItem5 {
  background: linear-gradient(
    180deg,
    #1d1d1f -4.84%,
    rgba(28, 35, 35, 0.968307) 0.47%,
    rgba(17, 112, 85, 0.04) 25.69%,
    #0c926a 51.15%,
    rgba(11, 150, 109, 0.981756) 56.7%,
    rgba(3, 203, 143, 0.72) 100%
  );
  div {
    background: #03cb8f;
  }
}
.galleryItems {
  display: flex;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 47.5%;
  padding: 0 3% 0 7%;
  gap: 1em;
  > p {
    color: styles.$white-3;
    text-align: center;
    @extend .body-3;
    @extend .add-bold;
  }
  aside {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      color: styles.$white-3;
      &:nth-child(1) {
        color: styles.$white-1;
        @extend .field-2;
      }
      &:nth-child(2) {
        color: styles.$white-1;
        @extend .button-2;
      }
      &:nth-child(3) {
        color: styles.$white-1;
        @extend .button-5;
      }
    }
  }
  .contentAuthor {
    display: flex;
    flex-direction: column;
  }
  .workBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: 79px;
    }
    span {
      font-weight: 500;
    }
    p {
      color: styles.$white-1;
      @extend .button-2;
    }
  }
}
