.header {
  width: var(--site-content2-width);
  margin: 10rem auto;
  display: grid;
  justify-content: center;
  align-items: center;
  column-gap: 8em;
  grid-template-areas:
    'image1 image2 image3'
    'imagesMiddle imagesMiddle imagesMiddle'
    'image4 image5 image6';
  @media (max-width: 768px) {
    column-gap: 2em;
  }
}

.headerMiddle {
  grid-area: imagesMiddle;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 4em;
  margin: 9em 0;
  align-self: center;
  @media (max-width: 768px) {
    gap: 2em;
    margin: 5em 0;
  }
  h1 {
    width: 60%;
    margin: 0 auto;
    @media (max-width: 768px) {
      width: 80%;
    }
  }
  h4 {
    width: 40%;
    margin: 0 auto;
    @media (max-width: 768px) {
      width: 80%;
    }
  }
}

.tabletImage1,
.tabletImage2,
.tabletImage3,
.tabletImage4,
.tabletImage5,
.tabletImage6 {
  justify-self: center;
  position: relative;
  grid-area: image1;
  img:first-child {
    width: 100%;
    object-fit: contain;
  }
}

.tabletImage1 {
  width: 100%;
  grid-area: image1;
  img:nth-child(2) {
    position: absolute;
    top: -10%;
    right: -6%;
    width: 20%;
  }
}

.tabletImage2 {
  top: -13%;
  width: 100%;
  grid-area: image2;
  img:nth-child(2) {
    position: absolute;
    bottom: -4%;
    left: -4%;
    width: 20%;
  }
}

.tabletImage3 {
  top: 13%;
  width: 100%;
  grid-area: image3;
  img:nth-child(2) {
    position: absolute;
    top: -12%;
    left: -12%;
    width: 35%;
    z-index: -1;
  }
}

.tabletImage4 {
  top: -20%;
  width: 100%;
  grid-area: image4;
  img:nth-child(2) {
    position: absolute;
    top: -20%;
    left: 2%;
    width: 45%;
  }
}

.tabletImage5 {
  width: 100%;
  grid-area: image5;
  img:nth-child(2) {
    position: absolute;
    top: -15%;
    right: -10%;
    width: 25%;
    z-index: -1;
  }
}

.tabletImage6 {
  top: -10%;
  width: 100%;
  grid-area: image6;
  img:nth-child(2) {
    position: absolute;
    top: -15%;
    left: 25%;
    width: 55%;
    z-index: -1;
  }
  img:nth-child(3) {
    position: absolute;
    bottom: -7%;
    right: -1%;
    width: 20%;
  }
}
