@use '../../../styles';

.container {
  position: relative;
  width: 1200px;
  padding-top: 35em;
  padding-bottom: 10em;
  padding-left: 2em;
  padding-right: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: styles.$white-2;
  border-radius: 18px;
  @media (max-width: 768px) {
    padding-top: 25em;
  }

  &.themeBlue {
    @extend .gradient-1;
  }

  &.themeGreen {
    @extend .gradient-4-2;
  }
  @media (max-width: 768px) {
    .rankingTitle {
      font-size: 2.2rem;
    }
  }
}

.ranking {
  display: flex;
  flex-direction: column;
  gap: 11em;
  border-top: 2px solid styles.$white-3;
  padding-top: 2.5em;
  margin-top: 8em;
  width: 95%;

  @media (max-width: 768px) {
    gap: 0;
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .top {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2em;

    .topEntry {
      display: grid;
      gap: 1em;
      grid-template-columns: 6fr 4fr;
      align-items: center;

      @media (max-width: 768px) {
        gap:3em;
      }

      @media (max-width: 1000px) {
        grid-template-columns: 1fr;
      }

      .entryVotes {
        display: flex;
        align-items: flex-start;
        gap: 3em;

        > div {
          display: flex;
          flex-direction: column;
          gap: 1em;
        }
      }
    }
  }

  .rest {
    width: 60%;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1em;
    margin: 0 auto;

    @media (max-width: 768px) {
      margin-top: 4em;
      width: 100%;
    }
  }
}

.prize {
  display: flex;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  > div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    @media (max-width: 768px) {
      flex-direction: row;
    }
  }

  .prizeImage {
    width: 150px;
    height: 150px;
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }
}

.circle {
  position: absolute;
  top: 3rem;
  left: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  @media (max-width: 768px) {
    top: 1rem;
    left: 1rem;
  }

  > :nth-child(2) {
    background-color: styles.$white-3;
    border-radius: 50%;

    path:nth-of-type(1) {
      stroke: styles.$blue-7;
    }
  }
}

.close {
  cursor: pointer;
  position: absolute;
  top: 3rem;
  right: 3rem;
  display: flex;
  align-items: center;
  gap: 1em;
}

.badge {
  width: 70px;
}

.circleText {
  text-align: center;

  &.themeBlue {
    color: styles.$orange-1;
  }

  &.themeGreen {
    color: styles.$blue-4;
  }
}
