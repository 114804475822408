@use '../../../../styles';

.contestSection {
  width: var(--site-content-width);
  margin: 0 auto;

  > :first-child {
    color: styles.$gray-7;
    width: 45%;
    @media (max-width: 768px) {
      margin: 10rem auto;
      width: 100%;
    }
    text-align: center;
    margin: 0 auto;
  }
}
