@use '../../../../styles';

.container {
  width: var(--site-content2-width);
  margin: 0 auto 15em auto;
  display: flex;
  flex-direction: column;
  gap: 4.5em;
  @media (max-width: 768px) {
    margin: 0 auto 10em auto;
  }
}

.header {
  width: 40%;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 3em;
  @media (max-width: 768px) {
    width: 80%;
  }
  > :nth-child(2) {
    color: styles.$gray-7;
  }
}

.dropdowns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3em;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 80%;
    margin: 0 auto;
  }
}

.dropdown {
  @extend .blue-shadow-1;
  padding: 2em 3em;
  background-color: styles.$white-4;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  justify-content: space-between;

  &.selected {
    background-color: styles.$winner-1;
    color: styles.$white-2;
  }
}
