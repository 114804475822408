@use '../../styles';

.nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  z-index: 2;
}

.background {
  position: absolute;
  top: 5em;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: styles.$white-2;
}
