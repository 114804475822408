@use '../../styles';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  background-color: rgba(styles.$gray-10, 80%);
  backdrop-filter: blur(5px);
  z-index: 10;
}

.popup {
  @extend .gray-shadow-6;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: styles.$white-2;
  border-radius: 18px;

  > * {
    max-width: 90vw;
    max-height: 90vh;
    overflow: auto;
  }
}
