@use '../../../../styles';

.banner {
  position: relative;
  @media (max-width: 768px) {
    height: 30em;
  }
  img {
    max-width: 100%;
    @media (max-width: 768px) {
      height: 100%;
      object-fit: cover;
    }
  }
  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    color: styles.$white-2;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
}
