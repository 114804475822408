@use '../../../../styles';

.cards {
  width: var(--site-content-width);
  margin: 0 auto 17em auto;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 3em;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    width: var(--site-content2-width);
    margin: 0 auto 5em auto;
    grid-template-columns: 1fr;
  }
  .card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 4em 0 2em 0;
    justify-content: flex-end;
    @extend .gray-shadow-6;
    background-color: styles.$white-4;
    border-radius: 18px;
    @media (max-width: 768px) {
      min-height: 45rem;
      width: 100%;
    }
    h2 {
      @media (max-width: 768px) {
        font-size: 3rem;
        line-height: 3.4rem;
      }
    }
    .finishedContests {
      align-self: flex-end;
      max-width: 270px;
      @media (max-width: 768px) {
        width: 50vw;
      }
    }
    .texts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 3em;
      width: 70%;
      margin: 3em auto;
      @media (max-width: 768px) {
        width: var(--site-content2-width);
        justify-content: flex-start;
      }
      > p:nth-of-type(2) {
        color: styles.$gray-7;
      }

      > :last-child {
        @media (max-width: 768px) {
          max-width: 270px;
        }
      }
    }

    &:nth-of-type(1) {
      padding: 4em 4em 2em 4.5em;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;
      @media (max-width: 768px) {
        padding: 3em;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 18px;
      }
    }
  }
}

.button {
  padding-left: 0;
}
