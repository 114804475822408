@use '../../styles';

.bar {
  background-color: styles.$black-1;
  color: styles.$white-3;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.5em var(--site-content2-half-margin);
  row-gap: 1em;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1.5em var(--site-content2-half-margin);
    row-gap: 2em;
  }
  svg {
    cursor: pointer;
    user-select: none;
  }
}

.left {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  column-gap: 5em;
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 2em;
  }
  .links {
    display: flex;
    flex-wrap: wrap;
    column-gap: 6em;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: row;

      row-gap: 2em;
    }
  }
}

.right {
  display: flex;
  gap: 10em;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
