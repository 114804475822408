@use '../../styles';

.allCards {
  display: flex;
  justify-content: space-between;
  width: var(--site-content-width);
  margin: 0 auto 4em auto;
  gap: 3em;
  @media (max-width: 650px) {
    width: var(--site-content2-width);
    flex-wrap: wrap;
    row-gap: 4em;
    margin: 0 auto 4em auto;
  }
  .singleCard {
    width: 50%;
    @extend .gray-shadow-6;
    border-radius: 18px;
    background-color: styles.$white-4;
    padding: 0 2.5em 2em 6em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 285px;
    h1,
    h2 {
      @media (max-width: 650px) {
        text-align: center;
      }
    }

    @media (max-width: 1000px) {
      padding: 1em;
    }
    @media (max-width: 650px) {
      min-width: unset;
      width: var(--site-content2-width);
      min-height: 450px;
      padding: 1em;
    }
    img {
      width: 100%;
      position: relative;
    }
  }
}
