@use '../../../../../../styles';

@import '~react-image-gallery/styles/scss/image-gallery.scss';

.image-gallery-bullets .image-gallery-bullet.active {
  background: black;
  border: none;
  &:hover {
    background: black;
  }
}

.image-gallery-bullets {
  bottom: -4rem;
}

.image-gallery-bullets .image-gallery-bullet {
  box-shadow: none;
  outline: 2px solid black;
  border: none;
  &:hover {
    background-color: black;
    border: none;
  }
}

.image-gallery-left-nav {
  left: -8rem;
  transform: translateY(-50%) rotate(180deg);
  filter: none;
}

.image-gallery-right-nav {
  right: -8rem;
  filter: none;
}

.image-gallery-image {
  max-width: 750px;
  object-fit: contain;
  border-radius: 15px;
  @extend .gray-shadow-6;
}

.image-gallery {
  margin-left: 8rem;
  margin-right: 8rem;
  @media (max-width: 768px) {
    margin: 0;
  }
}
