@use '../../../../styles';

.header {
  width: 45%;
  margin: 5em auto 10em auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 4em;
  @media (max-width: 1000px) {
    width: var(--site-content-width);
  }
  img {
    width: 100%;
    max-width: var(--site-min-width);
  }
}
.wrapper {
  margin-top: 15em;
  margin-bottom: 15em;
  .cardWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    top: -10em;
    @media (max-width: 650px) {
      top: 0;
      margin: 0 auto;
    }
  }
  .cardBottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      margin-top: 1em;
      margin-bottom: 1em;
    }
    @media (max-width: 650px) {
      gap: 1em;
    }
  }
  .openCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .cardUpper {
    height: 33em;
    @media (max-width: 650px) {
      height: 60%;
    }
    img {
      height: 100%;
      object-fit: contain;
    }
  }
}
