@use '../../../styles';

.container {
  width: 1032px;
  display: flex;
  padding: 4em 10em 4em 1.5em;
  align-items: center;
  @media (max-width: 768px) {
    padding: 2em;
    flex-direction: column;
  }
  img {
    @media (max-width: 768px) {
      height: 200px;
      width: auto;
      object-fit: contain;
      margin-bottom: 5em;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 3em;

  h3 {
    color: styles.$blue-9;
    width: 80%;
  }
}

.button {
  width: 200px;
  @media (max-width: 768px) {
    align-self: center;
  }
}
