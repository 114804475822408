.wrapper {
  position: relative;
  img {
    &:nth-child(1) {
      width: 429px;
      height: 401px;
      @media (max-width: 768px) {
        width: 90%;
        height: 100%;
        object-fit: contain;
      }
    }
    &:nth-child(2) {
      width: 299px;
      height: 190px;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 5%;
      text-align: center;
      @media (max-width: 768px) {
        top: -20%;
        width: 70%;
        height: 100%;
        object-fit: contain;
      }
    }
    &:nth-child(3) {
      width: 73px;
      height: 97px;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: -40%;
      right: 0;
      top: 73%;
      text-align: center;
      @media (max-width: 1368px) {
        display: none;
      }
    }
    &:nth-child(4) {
      width: 74px;
      height: 100px;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 40%;
      right: 0;
      top: 73%;
      text-align: center;
      @media (max-width: 1368px) {
        display: none;
      }
    }
  }
}
