@use '../../styles';

.header {
  background-color: styles.$black-1;
  color: styles.$white-3;
}

.socials {
  display: flex;
  align-items: center;
  gap: 1em;
  svg:hover {
    filter: brightness(150%);
  }
}

.actionHover {
  &:hover {
    text-decoration: underline;
  }
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 1.2em;
  span {
    color: styles.$white-3;
  }
}
