@use '../../../../styles';

.categoryTitle {
  width: var(--site-content-width);
  margin: 0 auto 3rem auto;
  @media (max-width: 768px) {
    width: var(--site-content2-width);
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 2em 1.5em;
}

.subjectAndName {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bottomButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
  svg {
    cursor: pointer;
  }
}

.categories {
  width: var(--site-content-width);
  margin: 0 auto 3rem auto;
  display: flex;
  gap: 2rem;
  align-items: center;
  @media (max-width: 768px) {
    width: var(--site-content2-width);
    flex-wrap: wrap;
  }
}

.category {
  background-color: styles.$gray-12;
  color: styles.$gray-13;
  padding: 1rem 2rem;
  border-radius: 6px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
  }
}

.categoryActive {
  background-color: styles.$gray-14;
  color: styles.$white-2;
  @extend .blue-shadow-1;
}

.subCategory {
  color: styles.$black-1;
  padding: 1rem 0;
  cursor: pointer;
}

.subCategoryActive {
  font-weight: bold;
}

.workLikeIconLikes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: styles.$orange-1;
  svg {
    width: 10px;
    height: 14px;
  }
}

.workLikeIconWithoutLikes {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
}

.cardsSummary {
  width: var(--site-content-width);
  margin: 5rem auto;
  @media (max-width: 768px) {
    width: var(--site-content2-width);
    margin: 2rem auto;
  }
}
