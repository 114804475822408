.revealCard {
  width: 50%;
  @media (max-width: 650px) {
    width: 100%;
  }
  > div {
    width: 100% !important;
    height: 100%;
  }
}

.revealHCard {
  > div {
    height: 100%;
    width: 100%;
  }
}
