@use '../../../../styles';
.commentsView {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 60%;
  margin: 0 auto;
  padding: 3rem 3rem;
  border-radius: 18px;
  background-color: styles.$white-3;
  @extend .gray-shadow-6;
}

.commentsViewHeader {
  margin-bottom: 3rem;
  font-weight: bold;
  text-align: center;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .navigationButtons {
    display: flex;
    align-items: center;
    gap: 1rem;
    p {
      font-weight: bold;
    }
  }
}
