@use '../../../../styles';

.container {
  margin: 8rem auto;
  min-height: calc(100vh - 80px - 16rem);
  width: var(--site-content-width);
  display: grid;
  align-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 374px 300px;
  column-gap: 3em;
  row-gap: 3em;
  @media (max-width: 768px) {
    height: auto;
    grid-template-columns: 1fr;
  }
  > :nth-child(2) {
    @extend .gradient-2;
    color: styles.$white-2;
    padding: 8em 7em;
    gap: 2em;

    @media (max-width: 1000px) {
      padding: 8em 3em;
    }

    &.error {
      @extend .gradient-6;
    }
  }

  > :nth-child(3) {
    gap: 3.5em;
  }
}

.card {
  min-height: 30em;
  height: 100%;
  @extend .gray-shadow-6;
  background-color: styles.$white-4;
  border-radius: 18px;
}

.wideCard {
  height: 374px;
  align-self: flex-end;
  grid-column: span 2;
  display: flex;
  padding-left: 9em;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0;
    row-gap: 2em;
    grid-column: unset;
  }
  h1 {
    @media (max-width: 768px) {
      margin-top: 1em;
      text-align: center;
    }
  }
}

.regularCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  .input {
    width: 100%;
  }
  h2 {
    text-align: center;
  }
}

.image {
  position: relative;
  top: 7rem;
  right: -8rem;
  min-width: 200px;
  object-fit: contain;
  width: 100%;
  border-radius: 0 0 18px 0;
  height: 480px;
  @media (max-width: 768px) {
    top: 5rem;
    height: 236px;
  }
}
