.breadcrumbs {
  display: flex;
  gap: 2em;
  align-items: center;
  height: 10em;
  width: var(--site-content2-width);
  margin: 0 auto;
  svg {
    cursor: pointer;
    transform: rotate(180deg);
  }
  a {
    display: flex;
    align-items: center;
    gap: 3px;
    &:last-child {
      font-weight: bold;
    }
  }
  .labels {
    display: flex;
    align-items: center;
    gap: 3px;
  }
}
