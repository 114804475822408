@use '../../../../styles';

.container {
  display: flex;
  flex-direction: column;
  gap: 4.5em;
  margin-bottom: 15em;
  @media (max-width: 768px) {
    margin-bottom: 5em;
  }
}

.header {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 40%;
  margin: 0 auto;
  gap: 2.5em;
  @media (max-width: 768px) {
    width: var(--site-content2-width);
  }
  > :nth-child(2) {
    color: styles.$gray-7;
  }
}

.contest {
  display: flex;
  align-items: center;
  gap: 7.5em;
  margin-left: var(--site-content2-half-margin);
  margin-right: var(--site-content-half-margin);
  @media (max-width: 768px) {
    flex-direction: column;
  }
  > img {
    width: 40%;
    @media (max-width: 768px) {
      width: 80%;
    }
  }
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  align-items: flex-start;
  @media (max-width: 768px) {
    text-align: center;
    align-items: center;
  }

  .dates {
    width: 100%;
    display: flex;
    gap: 1em;
    justify-content: space-between;
    margin-bottom: 3em;
    @media (max-width: 768px) {
      text-align: center;
    }
  }

  > p {
    width: 75%;

    &:nth-of-type(2) {
      color: styles.$gray-7;
      margin-bottom: 1em;
    }
  }
}
