@use '../../../../styles';
.contest {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 7em;
  row-gap: 5em;
  align-items: center;
  grid-template-areas:
    'media media . . .'
    'media media texts texts texts'
    'media media contestIncentive contestIncentive contestIncentive'
    'media media . . .';
  @media (max-width: 768px) {
    width: var(--site-content2-width);
    margin: 0 auto;
    column-gap: 3em;
    row-gap: 2em;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'media texts'
      'contestIncentive contestIncentive';
  }
}
.media {
  grid-area: media;
  margin-left: var(--site-content-half-margin);
  @media (max-width: 768px) {
    margin: 0 auto;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.texts {
  grid-area: texts;
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 5em;
  @media (max-width: 768px) {
    width: 100%;
  }
  h4 {
    color: styles.$gray-7;
  }
}
.contestIncentive {
  cursor: pointer;
  grid-area: contestIncentive;
  background-color: styles.$winner-1;
  height: 356px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 18px 0 0 18px;
  @extend .gray-shadow-1;
  padding: 0 6em;
  gap: 2em;
  @media (max-width: 768px) {
    border-radius: 18px;
    padding: 0 2em;
  }
  h2,
  p {
    color: styles.$white-2;
  }
  button {
    justify-content: flex-end;
  }
}
