@use '../../../styles';

.wrapper {
  display: flex;
  gap: 1.5em;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.disabled {
  cursor: not-allowed;
}

.container {
  position: relative;
  width: 32px;
  height: 16px;
  border: 1px solid styles.$white-1;
  border-radius: 100px;

  &.on {
    background-color: styles.$blue-5;
  }

  &.off {
    background-color: styles.$gray-4;
  }

  .circle {
    position: absolute;
    top: 1px;
    width: 12px;
    height: 12px;
    background-color: styles.$white-3;
    border-radius: 100px;

    &.on {
      right: 1px;
    }

    &.off {
      left: 1px;
    }
  }
}
