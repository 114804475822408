@use 'styles';

:export {
  mobileWidth: var(--site-content2-width);
}

:root {
  --site-content-width: 82%;
  --site-content-margin: 18%;
  --site-content-half-margin: 9%;
  --site-content2-width: 92%;
  --site-content2-margin: 8%;
  --site-content2-half-margin: 4%;
  --site-min-width: 280px;
  --site-max-width: 1512px;
}

.image-gallery-icon {
  @media (max-width: 600px) {
    display: none;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

html {
  font-size: 10px;
}

body {
  margin: 0 auto;
  max-width: var(--site-max-width);
  min-width: var(--site-min-width);
  color: styles.$black-1;
  background-color: styles.$white-2;
}

header,
footer {
  $no-scrollbar-width: calc(100vw - var(--scrollbar-width, 1vw));
  width: $no-scrollbar-width;
  margin-left: calc(calc(100% - $no-scrollbar-width) / 2);
}

body,
input,
textarea,
button,
select {
  scroll-behavior: smooth;
  font-family: 'SF Pro Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

iframe {
  display: block;
  border: none;
}

button,
input,
textarea,
select {
  color: styles.$black-1;
  outline: none;
  border: none;
  background-color: transparent;
}

select {
  appearance: none;
}

textarea {
  resize: none;
}

img {
  display: block;
}

ul,
ol {
  list-style: none;
}
