@use '../../../styles';

.wrapper {
  display: flex;
  align-items: center;
  gap: 1em;
  position: relative;
  padding: 1em 1em;
  p {
    color: styles.$black-1;
  }
  img {
    width: 48px;
    height: 48px;
    object-fit: cover;
  }
}
