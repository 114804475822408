@use '../../styles';

.wrapper {
  width: 100%;
  position: relative;
  margin: 0 auto;
  display: flex;
  padding: 0 1em;
  justify-content: space-between;
  align-items: center;
  border: 1px solid styles.$gray-8;
  .record {
    &:hover {
      text-decoration: underline;
    }
  }
  .leftIcon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .searchBar {
    padding: 1.3rem 0;
    color: styles.$gray-7;
    &.selected {
      text-decoration: underline;
    }
    &::placeholder {
      font-weight: 400;
      color: styles.$gray-9;
    }
  }
  .dropdown {
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% + 1em);
    border-radius: 8px;
    padding: 1.2em 1.5em;
    background-color: styles.$white-3;
    display: flex;
    flex-direction: column;
    gap: 0.8em;
    align-items: flex-start;
    .dropdownItem {
      color: styles.$gray-9;
      display: flex;
      gap: 0.5em;
      justify-content: space-between;
      p {
        user-select: none;
        cursor: pointer;
      }
      .hover {
        font-weight: 700;
        text-decoration: underline;
      }
      .hoverDisabled {
        color: styles.$gray-9;
      }
    }
  }
}
.defaultStyle {
  --search-icon-width: 6.4rem;
  background-color: styles.$white-3;
  border-radius: 8px;
  .searchBar {
    background-color: styles.$white-3;
    border-radius: 8px;
  }
}
.inputContainer {
  gap: 1.3rem;
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 1vw;

  input {
    width: 100%;
    border-radius: 0 !important;
  }
}

.container {
  margin: 0 auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1em;
  position: relative;
  z-index: 1;
}
