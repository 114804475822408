@use '../../../../styles';

.card {
  @extend .gray-shadow-6;
  cursor: pointer;
  width: var(--site-content-width);
  margin: calc(12% + 20em) auto 20em auto;
  background-color: styles.$white-4;
  border-radius: 18px;
  position: relative;
  @media (max-width: 768px) {
    width: var(--site-content2-width);
    margin: 10em auto 10em auto;
  }
  > svg {
    position: absolute;
    right: 1.5em;
    bottom: 2em;
  }
}

.opened {
  color: styles.$white-2;
  background-color: styles.$winner-1;
}

.openedContent {
  padding: 10em 13em;
  @media (max-width: 768px) {
    min-height: 80rem;
    padding: 4em 2em;
  }

  > :nth-child(1) {
    position: absolute;
    top: -120px;
    right: 130px;
    @media (max-width: 768px) {
      width: 120px;
      top: -80px;
      left: 10px;
    }
  }

  > :nth-child(2) {
    margin-bottom: 1.5rem;
  }

  > :nth-child(3) {
    margin-bottom: 4rem;
  }

  .points {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    .point {
      .dot {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: styles.$white-2;
        @media (max-width: 768px) {
          flex-shrink: 0;
        }
      }
      span {
        cursor: pointer;
      }

      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }
}

.closedContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10em;
  gap: 2em;
  h1 {
    @media (max-width: 768px) {
      width: 80%;
      text-align: center;
    }
  }
  > img {
    width: 50%;
    margin-top: -12%;
  }
}
