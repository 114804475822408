@use '../../../../styles';

.button {
  width: 200px;
  height: 48px;
}

.votingHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap:3rem;
  @media (max-width: 600px) {
    margin-bottom: 3rem;
  }
}

.timerPlace {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 3rem;
  align-items: center;
}
.prizeCheck {
  width: 174px;
  height: 105px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    white-space: pre-wrap;
    position: absolute;
    left: 70%;
  }
}

.buttonShare {
  border: 1px solid #020129 !important;
}

.votes {
  width: fit-content;
  position: relative;

  p {
    @extend .gradient-1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  img {
    position: absolute;
    top: -50px;
    right: -45px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: 2.5em;
  justify-content: space-between;
  flex-wrap: wrap;
  > div:first-of-type {
    display: flex;
    align-items: center;
    gap: 2.5em;
  }
}

.schoolPlace {
  color: #ee6d32;
}

.results {
  margin-top: 1em;
  display: flex;
  align-items: center;
  gap: 2em;

  > p {
    display: flex;
    gap: 0.5em;
  }

  > p:nth-child(1) {
    align-items: baseline;

    :nth-child(1) {
      @extend .gradient-1;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  > p:nth-child(2) {
    align-items: center;

    :nth-child(1) {
      @extend .gradient-3;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    :nth-child(2) {
      @extend .schoolPlace;
      width: 100px;
    }
  }
}

.contestTitle {
  h3 {
    font-weight: bold;
  }
  p {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
}
