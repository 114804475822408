@use '../../../../styles';

.dataBanner {
  display: flex;
  align-items: center;
  width: var(--site-content-width);
  margin: 8em auto 8em auto;
  height: 479px;
  border-radius: 18px;
  padding-left: 5em;
  padding-right: 5em;
  gap: 5em;
  @media (max-width: 768px) {
    flex-direction: column;
    width: var(--site-content2-width);
    margin: 0 auto;
    padding: 2em;
    height: max-content;
  }
  img {
    width: 35%;
    max-width: 394px;
    max-height: 395px;
    object-fit: contain;
    @media (max-width: 768px) {
      width: 100%;
      max-height: 30rem;
    }
  }
  h2 {
    width: 65%;
    font-weight: 400;
    font-size: calc(2rem + 1.2vw);
    color: styles.$white-2;
    @media (max-width: 768px) {
      text-align: center;
      font-size: 3rem;
      width: 100%;
    }
  }
  .motionVal {
    display: inline-block;
  }
}
