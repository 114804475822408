.cardsWrapper {
  width: 100% !important;
  padding: 0 !important;
}

.galleryWrapper {
  width: 100%;
  padding: 1em 0;
}

.galleryWrapperBottom {
  padding: 5em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  row-gap: 4em;
  cursor: pointer;
  h1 {
    text-align: center;
  }
}
