@use '../../../../styles';

.filters {
  margin: 4em auto;

  .filtersWrapper {
    flex-wrap: wrap;
    @media (max-width: 1000px) {
      justify-content: center;
    }
  }
  .filterWrapper {
    height: 254px;
    flex: 0 0 48%;
    padding-left: 10em;
    padding-top: 5em;
    @media (max-width: 1000px) {
      max-width: 350px;
      padding-left: 5em;
      padding-top: 2.5em;
    }
    @media (max-width: 768px) {
      padding: 2em;
      min-height: unset;
      height: max-content;
      flex-basis: 100%;
      align-items: center;
    }
    h2 {
      margin-bottom: 0.5em;
    }
    > div {
      width: 85%;
      // > div {
      //   border: 1px solid styles.$gray-8;
      // }
    }
  }
}
