.mobileHeader {
  position: relative;
  height: 5em;
  display: flex;
  align-items: center;
}
.logo {
  &:nth-child(2) {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 20%;
    text-align: center;
  }
}
.login {
  &:nth-child(3) {
    position: absolute;
    top: 10px;
    right: var(--site-content2-half-margin);
  }
}
.logout {
  &:nth-child(3) {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 1em;
    top: 10px;
    right: var(--site-content2-half-margin);
  }
}
