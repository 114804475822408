.introMessageAlt {
  width: var(--site-content-width);
  margin: 0 auto 10rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 auto 4rem auto;
  }
  img {
    position: relative;
    right: -10em;
    object-fit: contain;
    @media (max-width: 768px) {
      right: -5em;
      width: 50vw;
      margin-top: 3em;
    }
  }
  .texts {
    display: flex;
    flex-direction: column;
    gap: 3em;
    svg {
      transform: rotate(90deg);
    }
  }
}
