@use '../../../../styles';

.card {
  @extend .gray-shadow-6;
  background-color: styles.$white-4;
  border-radius: 15px;
  .file {
    width: 100% !important;
  }
}

.additionalMaterials {
  @extend .card;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 706px;
  padding: 4rem 8rem;
  gap: 2rem;
  @media (max-width: 768px) {
    padding: 2rem;
  }
  img {
    max-height: 393px;
    max-width: 422px;
    object-fit: contain;
    margin: 5rem auto 1rem auto;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  h3 {
    text-align: center;
  }
}

.files {
  display: flex;
  margin-top: 2em;
  gap: 3em;
  max-width: 706px;
  width: 100%;
  @media (max-width: 768px) {
    gap: 1em;
  }
  .file {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 50%;
    &.card {
      padding: 3.8rem 6rem 5.5rem 3rem;
      @media (max-width: 768px) {
        padding: 1.5em;
      }
    }
  }
}

.link {
  cursor: pointer;
  user-select: none;
}
