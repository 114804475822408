@use '../../../../styles';

.contestSection {
  width: 60%;
  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 6rem;
  }
   > svg:first-of-type {
    height: 0.0001px;
  }
}

.singleWorkView {
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: var(--site-content-width);
  margin: 10rem auto;
  min-height: 65rem;
  justify-content: center;
  @media (max-width: 768px) {
    height: unset;
    margin: 4rem auto;
  }
  .gallery {
    gap: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
  .materials {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.workDetails {
  display: flex;
  flex-direction: column;
  gap: 3em;
  width: 40%;
  @media (max-width: 1200px) {
    margin-top: 5em;
    width: 100%;
  }
  h4 {
    font-weight: bold;
  }
  .workStory {
    @extend .body-2;
    color: styles.$gray-7;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
    gap: 1em;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
  .titleAndContent {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
}
