@use '../../styles';

@mixin outline($color) {
  border: 1px solid $color;

  &::after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 4px solid $color;
    opacity: 20%;
    pointer-events: none;
  }
}

.inputContainer {
  position: relative;
  border: 1px solid styles.$gray-8;
  border-radius: 8px;
  background-color: styles.$white-3;

  input {
    color: inherit;
  }

  &.styleTransparent {
    border: 1px solid styles.$white-2;
    background-color: transparent;
  }

  &.disabled {
    cursor: not-allowed;
    color: styles.$gray-2;
    background-color: styles.$white-2;
    border: 1px solid styles.$gray-8;

    * {
      cursor: inherit;
    }
  }

  &.focus {
    @include outline(styles.$blue-8);
  }

  &.error {
    @include outline(styles.$red-7);
  }
}

.input {
  @extend .button-5;
  width: 100%;
  height: 50px;
  padding: 0.4rem 1.6rem;
  color: styles.$gray-9;
  border-radius: 8px;
  overflow: hidden;

  &.styleTransparent {
    color: styles.$white-2;

    &::placeholder {
      color: styles.$white-2;
    }
  }

  &::placeholder {
    @extend .body-3;
    color: styles.$gray-2;
  }
}

.floatingLabel {
  user-select: none;
  padding-top: 0.8rem;
  padding-left: 1.6rem;
  margin-bottom: -1.2rem;
  border-radius: 8px;
  text-align: start;
  color: styles.$gray-2;

  &.styleTransparent {
    color: styles.$white-2;
  }

  &.disabled {
    background-color: styles.$white-2;
  }
}

.wrapper {
  display: flex;
}

.toggle {
  padding-right: 1.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 8px 8px 0;
}
