@use '../../../../styles';

.contestBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10rem auto;
  padding: 0 10% 0 var(--site-content-half-margin);
  @extend .gradient-elementary-school-2;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: var(--site-content-half-margin) var(--site-content-half-margin);
  }
}

.contestIncentive {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  h4,
  h2,
  p {
    color: styles.$white-2;
  }
  button {
    padding: 0;
  }
}

.noContest {
  @extend .contestBanner;
  @extend .gradient-8;
  color: styles.$white-2;
}
