@use '../../../../styles';

.header {
  margin: 4.5em auto 12em auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 55%;
  @media (max-width: 768px) {
    width: var(--site-content2-width);
    margin: 5em auto 10em auto;
  }
  h4 {
    margin: 1em 0;
    color: styles.$gray-7;
    width: 90%;
  }
}

.imageWrapper {
  padding: 4.5em 15%;
}

.image {
  position: relative;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
  > img {
    width: 646px;
    height: 485px;
    border-radius: 18px;
    object-fit: cover;
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.decorations img {
  position: absolute;
}

.decorations1 img {
  &:nth-of-type(1) {
    top: -4%;
    left: -15%;
    width: 16%;
    height: auto;
    transform: matrix(-0.92, 0.38, 0.38, 0.92, 0, 0);
    z-index: -1;
  }

  &:nth-of-type(2) {
    bottom: 1%;
    left: -4%;
    width: 13%;
    height: auto;
    transform: rotate(47.49deg);
  }

  &:nth-of-type(3) {
    bottom: -4%;
    right: -4%;
    width: 11%;
    height: auto;
    transform: rotate(90.88deg);
    z-index: -1;
  }

  &:nth-of-type(4) {
    top: 35%;
    right: -8%;
    width: 13%;
    height: auto;
    transform: rotate(-29deg);
  }
}

.decorations2 img {
  &:nth-of-type(1) {
    top: 40%;
    left: -20%;
    width: 32%;
    height: auto;
    transform: rotate(65deg);
  }

  &:nth-of-type(2) {
    bottom: -4%;
    right: -7%;
    width: 13%;
    height: auto;
  }

  &:nth-of-type(3) {
    top: 2%;
    right: -15%;
    width: 42%;
    height: auto;
    transform: rotate(40deg);
    z-index: -1;
  }
}
