@use '../../../../styles';

.contestCards {
  margin-top: 7em;
  margin-bottom: 12em;
  display: grid;
  grid-template-columns: 7fr 3fr;
  grid-row-gap: 2.5em;
  grid-column-gap: 3em;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin: 0 auto;
  }
}

.contestCard {
  h2 {
    @media (max-width: 768px) {
      margin-top: 2em;
      font-size: 3rem;
      line-height: 3.4rem;
      width: 100%;
    }
  }
  > div {
    @extend .gray-shadow-6;
    cursor: pointer;
    background-color: styles.$white-4;
    border-radius: 18px;
    padding: 2.4em 3.2em;
    display: flex;
    flex-direction: column;
    position: relative;

    > img {
      position: relative;
      bottom: 3em;
      min-width: 250px;
      @media (max-width: 768px) {
        bottom: -3em;
        min-width: unset;
        width: 100%;
      }
    }

    .status {
      align-self: flex-start;
      position: absolute;
    }
  }

  &.statusCard > div {
    align-items: center;
    gap: 2em;
  }

  &.currentContestCard {
    cursor: pointer;
    > div {
      justify-content: space-between;

      .texts {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
      }
    }
    &.small {
      @media (max-width: 768px) {
        order: -1;
        min-height: 30em;
      }
    }
    &.small > div {
      .headline {
        @extend .headline-3b;
      }
    }

    &.big {
      grid-row: span 2;
      @media (max-width: 768px) {
        order: -1;
      }
      > div {
        .headline {
          @extend .add-bold;
          @extend .headline-2;
        }
      }

      &:not(.calendar) > div > :last-child {
        margin-bottom: 15rem;
      }
    }
  }

  &.primarySchool > div {
    @extend .gradient-1;
    color: styles.$white-2;
  }

  &.kindergarten {
    > div {
      @extend .gradient-4-2;
      color: styles.$white-2;
    }

    &.big {
      grid-row: 1 / span 2;
      grid-column: 2;
    }
  }

  &.calendar > div {
    @extend .gradient-8;
    color: styles.$white-2;

    > :last-child {
      align-self: center;
    }
  }
}
