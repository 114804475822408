@use '../../../../styles';

.primarySchool {
  @extend .gradient-3;
}

.kindergarten {
  background-color: styles.$regular-1Kinder;
}

.container {
  position: relative;
  width: var(--site-content-width);
  margin: 0 auto 18em auto;
  color: styles.$white-2;
  border-radius: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 3.2em;
  @media (max-width: 768px) {
    width: var(--site-content2-width);
    margin: 0 auto 6em auto;
    min-height: 30rem;
  }
  .text {
    h2 {
      @media (max-width: 768px) {
        font-size: 3rem;
        line-height: 3.8rem;
      }
    }
  }

  img {
    width: 50%;
    margin-top: -25px;
    margin-bottom: -50px;
    z-index: 0;
    @media (max-width: 768px) {
      width: 150px;
      position: absolute;
      margin-top: -70px;
      top: 0;
      right: 0;
    }
  }
}
