@use '../../../../styles';

.dataBanner {
  z-index: 0;
  position: relative;
  display: grid;
  grid-template-areas:
    'title title title'
    'img img img';
  width: var(--site-content-width);
  margin: 8em auto 8em auto;
  height: 479px;
  border-radius: 18px;

  @media (max-width: 768px) {
    width: var(--site-content2-width);
    margin: 0 auto;
    height: max-content;
    padding: 2em;
  }
  img {
    grid-area: img;
    object-fit: contain;
    align-self: flex-end;
  }
  h2 {
    z-index: 1;
    grid-area: title;
    width: 80%;
    color: styles.$white-2;
    @media (max-width: 768px) {
      font-size: 3rem;
      width: 100%;
    }
  }
}
