.gradient-1 {
  background: linear-gradient(104deg, #1570ec 0%, #02b0f5 106%);
}

.gradient-2 {
  background: linear-gradient(104deg, #079cb6 0%, #3dd8bd 106%);
}

.gradient-3 {
  background: linear-gradient(104deg, #ffc826 0%, #ff903d 50%, #ed6f9b 106%);
}

.gradient-4 {
  background: linear-gradient(104deg, #69a402 0%, #06742b 140%);
}

.gradient-4-2 {
  background: linear-gradient(104deg, #06742b -16%, #69a402 116%);
}

.gradient-5 {
  background: linear-gradient(104deg, #e67601 0%, #c35305 140%);
}

.gradient-6 {
  background: linear-gradient(104deg, #e11b1b 14%, #b10f0f 98%);
}

.gradient-7 {
  background: linear-gradient(104deg, #e7348d -8%, #cc1b56 114%);
}

.gradient-elementary-school-1 {
  background: linear-gradient(106.38deg, #079cb6 -2.37%, #3dd8bd 100%);
}

.gradient-elementary-school-2 {
  background: linear-gradient(99.96deg, #391bb0 -3.91%, #9644fd 103.39%);
}

.gradient-8 {
  background: linear-gradient(180deg, #020129 50.21%, #010015 125.37%);
}

.gradient-kindergarten-school-1 {
  background: linear-gradient(105.25deg, #1570ec -21%, #02b0f5 100.2%);
}
