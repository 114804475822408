@use '../../../../styles';

.wrapper {
  width: var(--site-content-width);
  margin: 0 auto;
  .playground {
    position: relative;
    padding: 5em 0;
    margin: 5em auto 0 auto;
    @media (max-width: 768px) {
      padding: 2em 0;
    }
    img {
      &:first-child {
        margin: 0 auto;
      }
    }
  }
  h1 {
    text-align: center;
  }
  h4 {
    color: styles.$gray-7;
    text-align: center;
    width: 50%;
    margin: 1.5em auto;
    @media (max-width: 768px) {
      margin: 2em auto;
      width: 100%;
    }
  }
}
