@use '../../../styles';

.container {
  width: 514px;
  padding: 3em;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  img {
    @media (max-width: 768px) {
      height: 200px;
      object-fit: contain;
    }
  }

  h3 {
    color: styles.$blue-9;
  }
}

.center {
  align-self: center;
}

.button {
  margin: 1.5em 0 !important;
  flex-shrink: 0;
}
