@use '../../../../styles';

.container {
  margin: 10rem auto;
  width: var(--site-content-width);
  min-height: calc(100vh - 80px - 20rem);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 707px;
  align-content: center;
  grid-gap: 3em;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 1200px) {
    grid-template-rows: 1fr 1fr;
  }

  > :nth-child(1) {
    padding: 10em 7em 7em 10em;
    display: flex;
    flex-direction: column;
    gap: 6em;
    @media (max-width: 1300px) {
      padding: 3em 6em 6em 6em;
    }
    @media (max-width: 768px) {
      padding: 3em 3em 3em 3em;
    }
  }

  > :nth-child(2) {
    @extend .gradient-1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7em 0;
  }
}

.card {
  @extend .gray-shadow-6;
  background-color: styles.$white-4;
  border-radius: 18px;
  @media (max-width: 1200px) {
    height: 40em;
  }
  @media (max-width: 768px) {
    height: 45em;
    width: 100%;
  }
  img {
    width: 585px;
    height: 540px;
    object-fit: contain;
    @media (max-width: 1200px) {
      height: 300px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2em;

  > :last-child {
    align-self: center;
    margin-top: 1em;
    width: 200px;
  }
}
