@use '../../../../styles';

.contests {
  width: var(--site-content-width);
  margin: 0 auto 14em auto;
  display: flex;
  flex-direction: column;
  gap: 4em;
  @media (max-width: 768px) {
    margin: 7em auto 10em auto;
  }
}

.contest {
  display: flex;
  align-items: center;
  gap: 10em;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .first {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2.5em;
    @media (max-width: 768px) {
      width: 100%;
    }
    > img {
      width: 100%;
      max-width: 250px;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    width: 60%;
    @media (max-width: 768px) {
      width: 100%;
    }
    > p {
      width: 100%;
      &:nth-of-type(2) {
        color: styles.$gray-7;
      }
    }
  }
}

.dates {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.standings {
  > div {
    margin-top: 1.5em;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 2rem;
  }
  > p {
    color: styles.$orange-1;
  }
}
